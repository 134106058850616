
.previous{
    font-family: Open sans-serif;
      font-size: medium;
      font-weight: 600;
      margin: 0px;
    cursor: pointer;
    color: #125BB2;
    .arrow{
        font-family: normal normal 600 14px/2px Open Sans;
        font-weight: 600;
        font-size: large;  
    }  
}
.current{
    font-family: Outfit Bold;
    font-weight:600;
    margin-left: 5px;
}

@media screen and (min-width:0px) and (max-width : 768px) {
    .current{
        // font-family: Outfit Bold;
        font: bold 17px Outfit;
        font-size: medium;   
    }
    .previous{
        font-size: medium;
       
        font: normal 17px Outfit;
        .arrow{
            font-family: Outfit Bold;
            font-weight: 600;
            font-size: large;  
            font: normal 19px Outfit;
        }  
    }
}