@import "../../constants/colors/colors.scss";
.container{
top: 128px;
left: 50px;
width: 1200px;
height: 600px;
background: $white 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px $custombuttonboxshadow;
border-radius: 21px;
opacity: 1;
}

.profilepic{
top: 173px;
left: 111px;
width: 66px;
height: 66px;
background: transparent url('C:\ACC\TATA CAPITAL\tcl-communication-inteface-ui\src\images\profilepic.png') 0% 0% no-repeat padding-box;
opacity: 1;
margin-top: 50px;
margin-left: 30px;
}
// .buttons{
//     margin-top: 30px;
// }
.label{
    color: $black;
    font: normal normal 600 14px/21px Open Sans;
    margin-left: 20px;
    }
    .box{
        top: 173px;
left: 111px;
width: 1035px;
height: 485px;
opacity: 1;
    }
    .profilebox{

        top: 173px;
left: 111px;
width: 1035px;
height: 485px;
opacity: 1;
    }