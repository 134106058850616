@import "../index.scss";
.tabs {
  font-family: arial;
  width: 100%;
  text-align: left;

  > div:first-child {
    border-bottom: 1px solid $lightGrey;
    padding-bottom: 10px;
  }
  .tabwrapper {
    border-bottom: 1px solid $lightGrey;
    padding-bottom: 10px;
    height: 50px;
  }
  .tab-buttons {
    margin-bottom: 50px;
  }

  button {
    width: 150px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600px;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    color: $darkBlack;
    transition: all ease-in-out 0.5s;
    border-bottom: 2px solid transparent;
    border-right: 1px solid $darkBlue;
    height: 45px;
  }

  .active {
    border-bottom: 5px solid $darkYellow;
    color: $darkBlue;
    font: 16px;
    font-family: sans-serif;
  }
  .disabled {
    background-color: lightgray;
    color: black;
    //margin: 5px;
    cursor: text;
  }
  .tab-content {
  }
}
