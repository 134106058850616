@import "../index.scss";
  .navbar-design {
    background-color:$darkBlue;
    height: 45px;
  }
  .left_logo img {
    top: 23px;
    left: 50px;
    width: 133px;
    height: 15px;
    background: transparent
      url("C:\ACC\TATA CAPITAL\tcl-communication-inteface-ui\src\images\TATA Capital.png")
      0% 0% no-repeat padding-box;
    opacity: 1;
    cursor: pointer;
  }
  .link {
    font: normal normal bold 16px/22px Open Sans;
    text-decoration: none;
    letter-spacing: 0px;
    display: flex;
    color: $white;
    padding: 10px 12px 10px 9px;
    gap: 10px;
  }
  .active {
    font: normal normal bold 16px/22px Open Sans;
    background-color: $darkBlue;
    color: $darkYellow;
    border-radius: 6px;
    .link_text {
      color: $darkYellow!important;
    }
  }
  .mobile-menu-bg {
    background-color: $darkBlue;
  }
  .logout{    
    background-color: $darkYellow;;
  font: normal normal bold 14px/17px Open Sans;
}