@import '../index.scss';

.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 200px;
  }
  
  .selectedOption {
    cursor: pointer;
    border: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    line-height: 20px;
    color:$darkBlue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
  }
  
  .optionsList {
    overflow-y: scroll;
    width: 230px;
    height: auto;
    max-height: 153px;
    list-style-type: none;
    font-size: 14px;
    position: absolute;
    top: 100%;
    left: 0%;
    z-index: 1;
    margin-top: 14px;
    background-color: $white;
    // border: 1px solid #ccc;
    box-shadow: 0px 2px 7px $blackTransparent1c;
    border-radius: 5px;
    padding-left: 0;
  }
  .optionsList::-webkit-scrollbar {
    display: none; /* Show scrollbar on hover */
  }
  .optionsList li {
    padding: 5px 5px 5px 14px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    background-color: $white;
  
  }
  
  .optionsList li:hover {
    background-color: $lightGrey2;
  }
  .arrow {
    height: 7px;
    width: 12px;
    // margin-right: 20px;
  }


  @media (max-width: 1200px) {
    .dropdownContainer {
      width: 170px;
    }

    .selectedOption{
      width: 170px;
    }
    .optionsList {
      width: 200px;
    }
}
@media (min-width: 769px) {
  .optionsList {
  left: -8%;
  }
}

@media (max-width: 768px) {
  .dropdownContainer {
    width: 100%;
    height: auto;
  }

  .selectedOption{
  justify-content: space-between;
  width: 100%;
  margin-left: 0px;
  padding-left: 2px;
  padding-right: 2px;
  }
img{
  margin-top: 2px;
}
  .arrow {
  margin-top: 2x;
  }

  .optionsList {
    width: 100%;
  }
}
  
  