@import "../index.scss";
.create-user-container{
  // max-height: 26rem;
  // overflow-y: scroll;
    background-color: $white;
    p{
      font-family: Open sans-serif;
      font-size: small;
      font-weight: 600;
      margin: 0px;
  
    }

    .create-button{
        width: 140px;
        height: 40px;
        background: $darkYellow 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $blackTransparent1c;
        border-radius: 10px;
        opacity: 1;
        font: normal normal bold 16px/22px Open Sans;
        color: $darkBlue;
    }
    .cancel-button{
        width: 140px;
        height: 40px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 0px $darkBlue;
        border-radius: 10px;
        opacity: 1;
        font: normal normal bold 16px/22px Open Sans;
        color: $darkBlue;
    }
    .textLabelRed {
      color: $red !important;
      font-size: 15px;
      font-weight: 600;
    }
    .position-relative {
      position: relative;
    }
    
    .position-absolute {
      position: absolute;    
    }
 
  
    


}