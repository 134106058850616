@import "../index.scss";
.custom-date-input {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-date-input input {
  border: 1px solid $darkBlue;
  border-radius: 10px;
  padding: 0px 32px 0px 12px;
  width: 100%;
  min-width: 240px;
  color: $darkBlue;
}

.calendar_icon {
  position: absolute;
  right: 10px; /* Adjust the icon's position within the input box */
  width: 35px; /* Adjust the icon width */
  height: 30px; /* Adjust the icon height */
  cursor: pointer;
}

.date-picker-heading {
  font-weight: bold;
  margin-bottom: 0.4em;
  font-size: 1em;
}

.div_wrapper {
  height: 39px;
  display: flex;
  flex-direction: row;
  margin: 0em;
  flex-wrap: nowrap;
  justify-content: center;
  width: 50%;
}
.clear_filter {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  text-decoration: none;
  color: #125bb2;
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 600;
  cursor: pointer;
}
.react-datepicker-popper {
  z-index: 101;
}
