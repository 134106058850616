$darkYellow:#D3E847;
$darkBlue:#125bb2;
$darkBlack:#000000;
$semiBoldGrey:#A5A5A5;
$white:#FFFFFF;
$red:#FF0000;
$lightBlue:#3c97bf;
$semidarkGrey:#D0D0D0;
$semiTransparentBlack:rgba(0, 0, 0, 0.3);
$deepGrey:"#222";
$grey3:#333;
$grey7:#777;
$blueGrey:#f6f9fc;
$lightGrey:#a5a5a5;
$lightGrey1:#e2e2e2;
$lightGrey2:#f0f0f0;
$lightGrey3:#ccc;
$blackTransparent1c:#0000001c;
$yellowgreen:#dbe448;
$lightGreen:#d7fada;
$tabcolor:#F7F7F7;
$login-bg: transparent linear-gradient(240deg, #D0DFEC 0%, #F7F7F7 66%, #CCF4ED 100%) 0% 0% no-repeat padding-box;
$outlook:#1976D2;
$statistictab:#F3F9FF;
$cardSubtitle:#3A3A3A;
$card-1:#FBF2FF;
$card-2:#F5FAFF;
$card-3:#F0FFFA;
$card-4:#F7F6FF;
$percentgreen:#1A8400;
$percentgreenbackground:#E7FFE1;
$trendred:#E30000;
$trendredbackground:#FFE1E1