@import "../index.scss";@import "../index.scss";

.mainwrapper {
  display: flex;
  flex-direction: column;
}

.searcwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownDateDownload {
  height: 50px;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-top: 19px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.downloadButtonMain {
  display: flex;
  align-items: center;
  border: 0.5px solid $darkBlue;
  border-radius: 10px;
  height: 40px;
  // width: 245px;
  justify-content: space-between;
  margin-right: 10px;
  margin-left: 40px;
  cursor: pointer;
}
.downloadText {
  font-size: 15px;
  font-weight: 400;
  font-family: sans-serif;
  text-align: center;
  color: $darkBlue;
  font-family: sans-serif;
  font-weight: 500;
  margin-left: 9px;
  padding: 0px 7px 0px 6px;
  cursor: pointer;
}
.downloadImg {
  width: 35px;
  height: 31px;
  margin-right: 9px;
  cursor: pointer;
}

.downloadImg img {
  width: 35px;
  height: 30px;
}

.customerNameMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // width: 500px;
  // min-width: 425px;
}

.profileImage img {
  width: 26px;
  height: 26px;
}

.accountName {
  color: $darkBlack;
  text-align: center;
  margin-left: 10px;
  width: 100%;
  text-align: left;
  font: normal normal 600 17px/24px Open Sans;
}

.rightside {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
}
.leftside {
  height: 24px;
  margin-left: 25px;
  display: flex;
  flex-direction: row;
}
.clearDropdown {
  margin-left: 10px;
  cursor: pointer;
}

.bottomMain {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000029;
  border-bottom-right-radius: 21px;
  border-top-right-radius: 21px;
  opacity: 1;
  width: 100%;
  height: 840px;
  background: $white 0% 0% no-repeat padding-box;
  margin-top: 15px;
  overflow-x: scroll ;
}
.leftBottomMenu {
  background-color: $blueGrey;
  opacity: 1;
  width: 270px;
  // height: 830px;
}
.righBottomtside {
  background-color: $white;
  width: 100%;
  border-bottom-right-radius: 21px;
  border-top-right-radius: 21px;
  padding-left: 24px;
  padding-right: 14px;
  padding-top: 4px;
}

.dropdownWrapper {
  min-width: 150px;
  width: 230px;
  height: 42px;
  box-shadow: 0px 2px 7px $blackTransparent1c;
  border-radius: 5px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  background-color: $white;
}

.clear_filter {
  color: $darkBlue;
  text-align: left;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: sans-serif;
  font-weight: lighter;
  cursor: pointer;
  margin-top: 35px;
  margin-left: 30px;
}

.lineContent {
  text-align: center;
}

.pagination_button {
  margin-top: 10px;
  text-align: center;
  text-decoration: underline;
  color: $darkBlue;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .searcwrapper {
    flex-direction: column;
  }
  .search {
    margin-top: 10px;
  }

  .dropdownDateDownload {
    flex-direction: column;
    height: 100px;
    align-items: center;
    justify-content: space-around;
  }

  .dropdownWrapper {
    width: 200px;
    border-radius: 5px;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 24px;
  }
  .leftBottomMenu {
    width: 200x;
  }
}

@media (max-width: 768px) {
  .rightside {
    width: 170px;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: space-evenly;
    height: 100px;
  }

  .dropdownDateDownload {
    height: 150px;
  }
  .dropdownWrapper {
    width: 97%;
  }

  .bottomMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin-top: 15px;
  }
  .leftBottomMenu {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .righBottomtside {
    width: 100%;
  }
  .accountName {
    font-size: 15px;
    height: 21px;
    margin-left: 5px;
    width: 70%;
  }
}

@media (max-width: 576px) {
  .customerNameMain {
    width: 100%;
    min-width: 70%;
  }

  .profileImage img {
    width: 21px;
    height: 21px;
  }
  .accountName {
    font-size: 8px;
    height: 15px;
    margin-left: 3px;
  }
  .leftside {
    height: 24px;
    margin-left: 7px;
  }
}
