@import "../index.scss";
.header{
    font: normal normal bold 16px/22px Open Sans;
    margin-bottom: 0px !important;
letter-spacing: 0px;
color: $darkBlue;
}
.role-user-container{
     background-color: $white;
    .nav-tabs .nav-link{
        width: 120px;
        background-color: $tabcolor;
        font: normal normal bold 16px/22px Open Sans;
        letter-spacing: 0px;
        color: $cardSubtitle;
        opacity: 1;
    }
    .nav-tabs{
        border: 0px;
    }
    .nav-tabs .nav-link.active {
        background-color: $darkBlue;
        color:$white;
    }
    .create-button{
        width: 140px;
        height: 40px;
        background: $darkYellow 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $blackTransparent1c;
        border-radius: 10px;
        opacity: 1;
        font: normal normal bold 16px/22px Open Sans;
        color: $darkBlue;
    }
  
}