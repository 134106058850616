
@import "../../constants/colors/colors.scss";
.customButtom {
  width: 150px;
  height: 40px;
  color: $blue;
  border: 1px solid $custombuttonborder;
  background-color: $custombuttonbg;
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  box-shadow: 0px 3px 6px $custombuttonboxshadow;
  margin-top: 8px;
  
}
