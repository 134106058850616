@import "../../constants/colors/colors.scss";
.cancelbutton{
    top: 625px;
left: 255px;
width: 145px;
height: 42px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000017;
border: 1px solid $blue;
border-radius: 10px;
opacity: 1;
color:$blue;
margin-left: 25px;
}