.main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}
.inlineLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}
