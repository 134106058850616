@import "../index.scss";
.mainTimeLinewrapper {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  position: relative;
}
.timelinewrapper {
  height: 600px;
  overflow: scroll;
}
.timelinewrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
  overflow-y: scroll;
  width: 100%;
  // height: 630px;
  *,
  ::after,
  ::before {
    box-sizing: unset !important;
  }
}

.dates {
  height: 22px;
  font: normal normal 600 14px/17px Open Sans;
  align-self: center;
  width: 138px;
  cursor: pointer;
  color: $white;
}

.timeline-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}
.rightTimelinediv {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.timelineMode {
  display: flex;
  width: 100px;
  height: 30px;
  margin-bottom: 10px;
  color: $white;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  justify-content: center;
}

.timeline-container::after {
  background-color: $darkBlue;
  content: "";
  position: absolute;
  left: calc(50% - -10px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 51%;
}
.top_div {
  margin-bottom: 10px;
  width: 170px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  margin-right: 40px;
}
.timeline-item:nth-child(odd) .top_div {
  width: 170px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 55px;
}

.timeline-item:nth-child(odd) {
  flex-direction: column;
  //   align-self: flex-end;
  justify-content: flex-start;
  padding-left: 246px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 196px;
  // height: 45px; // Commented to give even hight to timeline card
  max-width: 70%;
  text-align: right;
  //   margin-left: 20px;
  // overflow-y: scroll;
}
.timeline-item-content::-webkit-scrollbar {
  display: none; /* Show scrollbar on hover */
}

// @media (max-width: 576px) and (min-width: 420px){
@media (max-width: 576px) and (min-width: 420px) {
  .timeline-item:nth-child(odd) {
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 142px;
  }
  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -22px;
  }
  .rightTimelinediv {
    display: none;
  }

  .dates {
    height: 15px;
    width: 85px;
    font-size: 10px;
    margin-right: 10px;
  }
  .timeline-item:nth-child(odd) .top_div {
    width: 125px;
    margin-left: 0;
    margin-right: -5px;
  }
  .top_div {
    margin-right: 10px;
  }

  .timeline-item-content {
    width: 110px;
  }

  .timeline-item {
    margin-left: -10px;
  }
  .timeline-item:nth-child(odd) {
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 151px;
  }

  .tooltip-container .tooltip {
    display: none;
  }

  .tooltiptextmsg {
    display: none;
  }
  .timeline-item:nth-child(odd) .tooltip-container:hover .tooltip {
    display: none;
  }
}
.timeline-item-content::after {
  content: " ";
  //   background-color: #fff;
  //   box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  //   box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: $white;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content time {
  color: $grey7;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 12px;
  line-height: 19px;
  margin: 0px 0;
  max-width: 196px;
  overflow-y: scroll;
}
.timeline-item-content a {
  color: $grey3;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}
.timeline-item-content p::-webkit-scrollbar {
  display: none; /* Show scrollbar on hover */
}
.timeline-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: $darkYellow;
  border: 1px solid $darkBlue;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Adjust the transform property to center horizontally */
  right: -60px;
  width: 15px;
  height: 15px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -24px;
}
@media only screen and (min-width: 768px) {
  .timeline-item-content .circle {
    right: -51px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
  // .timeline-item-content .circle {
  //   right: -58px;
  // }
  .rightTimelinediv {
    display: none;
  }

  .timeline-item:nth-child(odd) .top_div {
    margin-right: 42px;
  }
}
@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 70%;
  }
}

@media only screen and (max-width: 1800px) {
  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -19px;
  }

  .timeline-item-content .circle {
    right: -56px;
  }
}
@media only screen and (max-width: 1500px) {
  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -16px;
  }

  .timeline-item-content .circle {
    right: -59px;
  }
}

@media only screen and (max-width: 1200px) {
  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -13px;
  }

  .timeline-item-content .circle {
    right: -62px;
  }
}
@media only screen and (max-width: 576px) {
  .timeline-item-content {
    padding: 5px 5px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
  }

  .timeline-item:nth-child(odd) {
    flex-direction: column;
    //   align-self: flex-end;
    justify-content: flex-start;
    padding-left: 236px;
  }

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }

  .timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -13px;
  }

  .timeline-item-content .circle {
    right: -68px;
  }
}

// tooltip on the msg

.tooltip-container {
  position: relative;
  display: inline-block;
}
.tooltip {
  font-size: 10px;
  line-height: 17px;
  width: 110px;
}
.tooltiptextmsg {
  background-color: $white;
  color: $darkBlack;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-size: 12px;
  line-height: 17px;
  width: 250px;
}

.tooltip-container .tooltiptextmsg {
  visibility: hidden;
  width: 0;
  color: $darkBlack;
  text-align: center;
  border-radius: 6px;
  padding: 3px;
  position: absolute;
  z-index: 100;
  top: 90%;
  right: 50%;
  opacity: 0;
  transition: opacity 0.3s, width 0.3s ease-in-out;
  word-wrap: break-word;
}

.timeline-item:nth-child(odd) .tooltip-container:hover .tooltiptextmsg {
  visibility: hidden;
  width: 0;
  color: $darkBlack;
  text-align: center;
  border-radius: 6px;
  padding: 3px;
  position: absolute;
  z-index: 1;
  top: 90%;
  left: 33%; /* Set right instead of left */
  opacity: 0;
  transition: opacity 0.3s, width 0.3s ease-in-out;
  word-wrap: break-word;
}

/* For odd tooltips (left to right) */
.timeline-item:nth-child(odd) .tooltip-container:hover .tooltiptextmsg {
  visibility: visible;
  width: 240px; /* or the desired width */
  opacity: 1;
  z-index: 1000;
}

/* For even tooltips (right to left) */
.timeline-item:nth-child(even) .tooltip-container:hover .tooltiptextmsg {
  visibility: visible;
  width: -240px; /* or the desired width */
  opacity: 1;
  z-index: 1000;
}
/* For both odd and even tooltips */
.tooltip-container:hover .tooltiptextmsg {
  visibility: visible;
  width: 240px; /* or the desired width */
  opacity: 1;
  z-index: 1000;
}

.tooltip-container .tooltip {
  visibility: hidden;
  width: 0;
  color: $darkBlack;
  text-align: center;
  border-radius: 6px;
  padding: 3px;
  position: absolute;
  z-index: 1;
  top: -10%;
  right: 100%; /* Set right instead of left */
  opacity: 0;
  transition: opacity 0.3s, width 0.3s ease-in-out;
}

.timeline-item:nth-child(odd) .tooltip-container:hover .tooltip {
  visibility: hidden;
  width: 0;
  color: $darkBlack;
  text-align: center;
  border-radius: 6px;
  padding: 3px;
  position: absolute;
  z-index: 1;
  top: -10%;
  left: 100%; /* Set right instead of left */
  opacity: 0;
  transition: opacity 0.3s, width 0.3s ease-in-out;
}

/* For odd tooltips (left to right) */
.timeline-item:nth-child(odd) .tooltip-container:hover .tooltip {
  visibility: visible;
  width: 110px; /* or the desired width */
  opacity: 1;
  z-index: 1000;
}

/* For even tooltips (right to left) */
.timeline-item:nth-child(even) .tooltip-container:hover .tooltip {
  visibility: visible;
  width: 110px; /* or the desired width */
  opacity: 1;
  z-index: 1000;
}

/* For both odd and even tooltips */
.tooltip-item:hover .tooltip {
  visibility: visible;
  width: 110px; /* or the desired width */
  opacity: 1;
  z-index: 1000;
}
.msg-status-img {
  height: 16px;
  width: 21px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
}

// Modal right
.modal_right {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  min-width: 450px;
  max-width: fit-content;
  height: 100vh;
  padding: 18px 23px 0px 27px;
  box-shadow: 0px 0px 10px $darkBlack;
  background-color: $white;
  background: $white 0% 0% no-repeat padding-box;
  overflow: scroll;
}
@media (max-width: 1200px) and (min-width: 1024px) {
  .modal_right {
    width: 400px;
    overflow: scroll;
  }
}

@media (max-width: 1023px) and (min-width: 420px) {
  .modal_right {
    width: 300px;
    overflow: scroll;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 6px;
}
.closeButton img {
  height: 29px;
  width: 29px;
  cursor: pointer;
}
.date_download {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.campaingn_name {
  text-align: left;
  color: $darkBlue;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
}
.downloadButtonMain_modal {
  display: flex;
  align-items: center;
  border: 0.5px solid $darkBlue;
  border-radius: 10px;
  height: 42px;
  width: 140px;
  justify-content: space-between;
}
.downloadText_modal {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  font-family: sans-serif;
  text-align: center;
  color: $darkBlue;
  font-family: sans-serif;
  // margin-left: 9px;
  margin-right: 9px;
}
.downloadImg_modal {
  width: 30px;
  height: 30px;
  // margin-right: 9px;
  cursor: pointer;
}
.campaingn_Msgname {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  border-bottom: 1px solid $semidarkGrey;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.campaingn_Msgname div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $darkBlack;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  font-family: sans-serif;
  opacity: 1;
}

.greeting_msg {
  white-space: pre-wrap;
  max-width: 510px;
  overflow-wrap: break-word;
  text-align: start;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  opacity: 1;
  color: $darkBlack;
  margin-top: 1rem;
}
.temp_div .preview {
  color: $darkBlue;
  cursor: pointer;
  text-decoration: underline;
}

.modal-div {
  max-width: 80vw !important;
}
.close-button {
  cursor: pointer;
}

@media (max-width: 768px) and (min-width: 420px) {
  .campaingn_Msgname {
    flex-direction: column;
    height: 70px;
  }
}
