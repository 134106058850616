.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 0.5s ease-out forwards; /* Fade in animation */
}

.loading-indicator:after {
    content: 'Loading...';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 0.5s ease-out forwards, bounce 1.5s infinite; /* Fade in and bounce animations */
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(-50%);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-10px);
    }
}
