@import '../index.scss';
.container {
  position: relative;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 380px;
  height: 38px;
  background-color: $white;
  align-items: center;
  border: 0.5px solid $lightGrey1;
  border-radius: 6px;
  min-width: 230px;
  position: relative; /* Add this line for positioning the error message */
  margin-top: 10px;
}
.searchImage {
  height: 15px;
  width: 15px;
  margin-right: 9px;
}

.inputField {
  border: none;
  width: 100%;
  font: normal normal 600 13px/20px Open Sans;
  margin-left: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.inputField:focus {
  outline: none;
  border: none;
}

.inputField::placeholder {
  color: $darkBlue;
}

.error {
  border: 2px solid $red;
}

.errorInput {
  color: $red;
}

.errorMessage {
  position: absolute;
  // top: -18px;  // Adjust the value to position the error message above the input box
  left: 5px;  // Adjust the value to position the error message horizontally
  color: $red;
  font-size: 0.8rem;
}
.errorOutline{
  border:2px solid red ;
  
}

@media screen and (min-width: 1200px) and (max-width: 1800px) {
  .main {
    width: 200px;
  }
}

@media screen and (max-width: 414px) {
  .main {
    width: 330px;
  }
}
