.layout-container {
  overflow: hidden; /* Add this to prevent body scrolling */
  display: flex;
  flex-direction: column;
  width: 100vw; /* Corrected to 100vw for full viewport width */
}

.header {
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 45px;
}

.content {
  overflow-x: auto;
  flex: 1;
  overflow-y: auto;
  background: linear-gradient(242deg, #d0dfec 0%, #f7f7f7 66%, #ccf4ed 100%) 0%
    0% no-repeat;
  opacity: 1;
  min-height: calc(
    100vh - 45px
  ); /* Adjust this value to consider the height of the header */
  position: relative;
  top: 45px;
  padding: 13px 51px 0px 51px;
}
@media (max-width: 768px) {
  .content {
    padding: 20px 31px 0px 31px;
  }
}
