
@import '../index.scss';
.staticstics-container{
    .div_wrapper{
        justify-content:flex-start;   
    }
   
  .sub-header{

    .nav-link {
        padding-right: 50px !important;
    }

    .download-btn {
        height: 39px;
        display: flex;
        margin: 0px 20px;
    }
    .download-main {
        background-color: #D3E847;
    font: normal normal bold 14px/17px Open Sans;
    border: 1px solid #125bb2;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        .div_wrapper{
            margin-top: 5%;
            margin-bottom: 5%;
        }
        .mobileview_date {
            margin-left: 0 !important;
        }
    }
  }
    .card-1,.card-2,.card-3,.card-4{
        
        .card-subtitle{
            font-family: Open Sans;
            color: $cardSubtitle;
            font-size: 0.9rem;
            margin-top: 0.4rem;
        
        }
        .percentage{
            color: $percentgreen;
            background-color: $percentgreenbackground;
            font-family:  Open Sans;
            font-weight: bold;
            font-size: 0.7rem;
            letter-spacing: 0px;   
        }
        .count{
            color: $darkBlack;
            font-family: Open Sans;
            font-weight: 900;
            font-size: 1.5rem;
            
        }
        .card-count{
            color: $darkBlack;
            font-family: Open Sans;
            font-weight: 500;
            font-size: 1.5rem;
            
        }
        .card-link{
            text-decoration: none;
            text-align: left;
            font-family: Open sans;
            font-weight: bold;
            color: $cardSubtitle;
            font-size: 0.9rem;
            margin: 0;
            
        }
        .seperator{
            color: $semidarkGrey;
        }
    }
    .card-1{
        background-color: $card-1;
    }
    .card-2{
        background-color: $card-2;
    }
    .card-3{
        background-color: $card-3;
    }
    .card-4{
        background-color: $card-4;
    }
   p{
    font-family: Open sans;
    color: $darkBlack;
    font-weight: bolder;
    margin: 0px;
    letter-spacing: 0px;
   }
    .nav-item{
        font-family: Open Sans;
        font-weight: 900;
        font-size: 0.8em;
        
    }
    .nav-link{
        transition: none;
        color: $darkBlack;
    }
    .dropdown{
        font-family: Open Sans;
        font-weight: 600;
        font-size: 0.8em;
        color: $darkBlack;
    
    }
    .dropdown::placeholder{
        color: $semiBoldGrey;
    }
    .css-1r4vtzz,.css-48ayfv{
       // min-width: 18.2em;
       position: relative;
    }
    .css-1jllj6i-control{
        display: none;
    }
      .nav-link.active{
        border: none;
        font-family: Open Sans;
        font-weight: 600;
        font-size:1em;
        color: $darkBlue;
        background-color: $statistictab
      
    }
    .tab-content{
       // width: 20%;
        margin-left: 2%;
       
        .active{
            background-color: transparent;
        }
    }
   
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover{
        border: none;
        font-family: Open Sans;
        font-weight: 600;
        font-size:1em;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        .tab-content{
            width: 100%;
            margin-left: 0;
        }
    }
    .graph{
        .graph-drop {
            font-family: 'Open sans';
            color: $grey3;
        }
    }
        .upward {
            color: $percentgreen;
            background-color: $percentgreenbackground;
            font-family:  Open Sans;
            font-weight: bold;
            font-size: 0.7rem;
            letter-spacing: 0px;  
        }
        .downward {
            color: $trendred;
            background-color: $trendredbackground;
            font-family:  Open Sans;
            font-weight: bold;
            font-size: 0.7rem;
            letter-spacing: 0px;  
        }
    .overviewIcon{
        width: 17px;
        align-items: center;
        cursor: pointer;
    }
}