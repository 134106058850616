.mainwrapper {
  // display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  position: relative;
}

.rightTimelinediv {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.timelineMode {
  display: flex;
  width: 100px;
  height: 30px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  justify-content: center;
}
