.rdt_Table{
    overflow-y:hidden ;
}
.epZUJV{
    display: none;
}
.fAiwtk{
    display: none;
}

