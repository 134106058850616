@import "../index.scss";
.login-container{
    background: $login-bg;
    height: 100vh;
    width: 100vw;
    .login-box{
        border:1px solid $lightGrey;
        border-radius: 10px;
        background-color: $white;
        p{
            font-family: Open Sans;
            font-weight: bold;
            font-size:larger;
        }   color:$darkBlack ;
        .outlook-btn{
            font-family:  Open Sans;
            font-weight: 600;
           text-align: center;
           border: 1px solid $outlook;
           border-radius: 7px;
           color: $outlook;
         
        }
    }
    @media screen and (max-width: 768px) {
    .login-img{
        display: none !important;
        
    }
    .login-box{
        justify-content: center;
    }
    }
}