@import '../index.scss';
.dropdownContainer {
  position: relative;
  display: inline-block;
  width: 242;
}

.selectedOption {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font: normal normal normal 18px/24px Open Sans;
  margin-left: 10px;
  width: 242px;
  text-align: center;
  color: $darkBlack;
  position: relative;
  .arrow {
    height: 7px;
    width: 12px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.optionsList {
  text-align: center;
  overflow-y: scroll;
  width: 242px;
  // height: 153px;
  // max-height: 153px;
  height: auto;
  list-style-type: none;
  font-size: 14px;
  padding: 15px;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: $white;
  // border: 1px solid $lightGrey3;
  box-shadow: 0px 2px 7px $blackTransparent1c;
  border-radius: 5px;
}

.optionsList::-webkit-scrollbar {
  display: none; /* Show scrollbar on hover */
}
.optionsList li {
  // padding: 5px 5px 5px 14px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 15px;
  // margin-left: 27px;
}

.optionsList li:hover {
  background-color: $lightGrey2;
}
.activeOption {
  background-color: $lightGrey2;
}
// @media (min-width: 769px) {
//   .optionsList {
//   left: -35px;
//   }
// }

@media (max-width: 425px) {
  .optionsList {
    left: 94px;
    width: 152px;
  }
  .optionsList li {
    font-size: 10px;
  }
  .selectedOption {
    width: 152px;
  }
}
@media (min-width: 426px) {
  .optionsList {
    left: 15px;
  }
}

@media (min-width: 769px) {
  .optionsList {
    left: 15px;
  }
}
@media (max-width: 768px) {
  .selectedOption {
    font-size: 15px;
    margin-left: 5px;
  }
  .dropdownContainer {
    width: 90%;
  }
  // .optionsList {
  //   left: -70px;
  //   }
}

@media (max-width: 576px) {
  .selectedOption {
    font-size: 8px;
    margin-left: 3px;
    width: 100%;
  }
}
